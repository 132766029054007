import { Delete } from "@mui/icons-material";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Fab,
  List,
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";

const TerritoriesList = ({
  composition,
  removeTerritory,
  included = false,
}) => {
  return (
    <>
      {composition.composition_territories
        .filter((territory) => territory?.inclusion === included)
        .map((selectedTerritoy) => (
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              minWidth: "200px",
              backgroundColor: included ? "#E7FFD9" : "#FFE6E6",
            }}
          >
            <Stack spacing={3}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant="button">
                  {selectedTerritoy?.territory?.name}
                </Typography>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => removeTerritory(selectedTerritoy.territory)}
                >
                  <Delete />
                </Fab>
              </Stack>

              <Stack spacing={2}>
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  label="TIS Code"
                  value={selectedTerritoy?.territory?.tis_code}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  fullWidth
                  disabled
                  size="small"
                  label="Percentage"
                  value={selectedTerritoy?.percentage + "%"}
                  InputProps={{ readOnly: true }}
                />
              </Stack>
            </Stack>
          </Paper>
        ))}
    </>
  );
};

export default TerritoriesList;
