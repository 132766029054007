import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Colors from "../enums/colors";
import { CustomisationContext } from "../context/customisation";
import { AuthContext } from "../context/auth";
import useCountries from "../hooks/country/countries.hook";
import { Country } from "../models/country/country";
import ActionButtonSmall from "./layout/buttons/action-button-small";
import API from "../api/api";
import ApiUri from "../api/api-uri";
import { SnackbarContext } from "../context/snackbar";
import { GlobalLoaderContext } from "../context/global-loader";
import { useHistory } from "react-router-dom";
import NewProfileForm from "./NewProfileForm";
import ChooseInvitationPage from "./ChooseInvitationPage";
import ProfileTypes from "../enums/profile-types";
import { useParams } from "react-router-dom";

const params = new URLSearchParams(window.location.search);
let parentParam: any = params.get("parent");
let profileTypeParam = params.get("profile_type");
// const { parent, profile_type } = useParams();
// let parentParam = parent;
// let profileTypeParam = profile_type;

if (!profileTypeParam) {
  profileTypeParam = ProfileTypes.SONGWRITER;
}
if (profileTypeParam) {
  profileTypeParam =
    profileTypeParam.charAt(0).toUpperCase() + profileTypeParam.slice(1);
}
// comment out next 10 lines for api response test
const profileTypeIsValid =
  profileTypeParam === ProfileTypes.SERVICE ||
  profileTypeParam === ProfileTypes.PUBLISHER_ADMINISTRATOR ||
  profileTypeParam === ProfileTypes.SONGWRITER ||
  profileTypeParam === ProfileTypes.PUBLISHER;

const useHorusMusicPublishingAndSongwriter = !parentParam;
if (useHorusMusicPublishingAndSongwriter) {
  parentParam = "horus-music-publishing";
  profileTypeParam = ProfileTypes.SONGWRITER;
}

const NewProfilePage = () => {
  const { customisation } = useContext(CustomisationContext);
  const { user } = useContext(AuthContext);
  const [logoURL, setLogoURL] = useState<string | undefined>(undefined);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [showNewProfileForm, setShowNewProfileForm] = useState(false);
  const [showChooseInvitationPage, setShowChooseInvitationPage] =
    useState(false);
  const [notAcceptedInvitations, setNotAcceptedInvitations] = useState([]);
  const [mainColor, setMainColor] = useState("#ffffff");

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const history = useHistory();

  useEffect(() => {
    startGlobalLoader();
    API.get("/public" + ApiUri.PROFILE + "/" + parentParam).then(
      (response) => {
        const responseProfile = response.data;
        setMainColor(responseProfile.profile_information.main_color);
        setLogoURL(responseProfile.profile_information.file.url);
      },
      (err) => {}
    );
    const emailQuery = { email: user?.email };
    API.get("/invitation", { params: emailQuery }).then(
      (response) => {
        const invitations = response.data.collection;
        const newNotAcceptedInvitations = invitations.filter(
          (invitation) => invitation?.accepted_at === null
        );
        if (newNotAcceptedInvitations.length === 0) {
          setShowNewProfileForm(true);
          stopGlobalLoader();
        } else {
          setNotAcceptedInvitations(newNotAcceptedInvitations);
          setShowChooseInvitationPage(true);
          stopGlobalLoader();
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  }, []);

  return (
    <Stack
      sx={{ backgroundColor: mainColor }}
      alignItems={"center"}
      minHeight={"100vh"}
      paddingBottom={3}
      paddingTop={2}
    >
      <img
        src={logoURL}
        alt=""
        width="100%"
        loading="eager"
        style={{
          width: "100%",
          maxHeight: "150px",
          objectFit: "scale-down",
        }}
      />
      {showNewProfileForm && (
        <NewProfileForm parent={parentParam} profileType={profileTypeParam} />
      )}
      {showChooseInvitationPage && (
        <ChooseInvitationPage invitations={notAcceptedInvitations} />
      )}
    </Stack>
  );
};

export default NewProfilePage;
