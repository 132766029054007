import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Theme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Navigation from "./navigation";
import API from "../api/api";
import NavigationContextProvider from "../context/navigation";
import { Box, Divider, Stack, Typography } from "@mui/material";
import AppSelector from "./app-selector";
import UserProfileDropdown from "./user-profile-dropdown";
import ProfileSelector from "./profile-selector";
import ConfirmationDialogContext from "../context/confirmation-dialog-context";
import NotificationsDropdown from "./notifications-dropdown";
import NotificationsContextProvider from "../context/notifications";
import { CustomisationContext } from "../context/customisation";
import CustomToolbar from "../components/layout/titles/CustomToolbar";
import ApiUri from "../api/api-uri";
import { SnackbarContext } from "../context/snackbar";
import { Person } from "@mui/icons-material";
import ParentProfileButton from "./ParentProfileButton";
import { AuthContext } from "../context/auth";
import { GlobalLoaderContext } from "../context/global-loader";
import { getCompleteProfile } from "../api/utils/get/getCompleteProfile";
import ProfileTypes from "../enums/profile-types";
import { Prompt, useHistory } from "react-router-dom";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const drawerWidth = 195;

function BaseComponent(props: any) {
  const { customisation } = useContext(CustomisationContext);
  const { user } = useContext(AuthContext);
  const { window } = props;
  const { initSnackbarError } = useContext(SnackbarContext);
  const [logoURL, setLogoURL] = useState<string | undefined>(undefined);
  const [parentLogoURL, setParentLogoURL] = useState<string | undefined>(
    undefined
  );
  const [isFetchingLogoURL, setIsFetchingLogoURL] = useState<boolean>(false);
  const [parentProfile, setParentProfile] = useState<any>(undefined);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const useStyles = makeStyles(function (theme) {
    return {
      root: {
        display: "flex",
        marginTop: 20,
      },
      drawer: {
        [theme.breakpoints.up("sm")]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        [theme.breakpoints.up("sm")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
        boxShadow: "none",
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      drawerPaper: {
        width: drawerWidth,
        backgroundColor: customisation?.mainColor,
        borderRight: "none",
      },
      content: {
        flexGrow: 1,
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    };
  });

  useEffect(() => {
    const fetchParentProfile = async () => {
      try {
        const parentProfileResponse = await getCompleteProfile({
          profileId: user?.last_accessed_profile?.parent?.id,
        });
        const parentProfile = parentProfileResponse.data;
        setParentProfile(parentProfile);
      } catch (err) {}
    };
    fetchParentProfile();
  }, []);

  useEffect(() => {
    const imageId = customisation?.logo?.id;
    if (imageId) {
      setIsFetchingLogoURL(true);
      API.get(ApiUri.FILE + "/" + imageId, { responseType: "blob" }).then(
        (response) => {
          setLogoURL(URL.createObjectURL(response.data));
          setIsFetchingLogoURL(false);
        },
        (error) => {
          setIsFetchingLogoURL(false);
          initSnackbarError(error);
        }
      );
    }
  }, [customisation?.logo]);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Stack paddingY={2} spacing={1}>
      {isFetchingLogoURL ? (
        <div style={{ width: "100%", height: "150px" }}></div>
      ) : (
        <img
          src={logoURL || "/mmpz-logo.svg"}
          alt=""
          width="100%"
          loading="eager"
          style={{
            width: "100%",
            maxHeight: "150px",
            objectFit: "scale-down",
          }}
        />
      )}

      <NavigationContextProvider>
        <Navigation />
        {parentProfile &&
          (user?.last_accessed_profile?.profile_type?.name ===
            ProfileTypes.SONGWRITER ||
            user?.last_accessed_profile?.profile_type.name ===
              ProfileTypes.PUBLISHER) && (
            <Stack m={0} paddingTop={5}>
              <Typography
                paddingLeft={"10%"}
                paddingBottom={"5px"}
                sx={{
                  fontSize: "10px",
                  fontWeight: 700,
                  lineHeight: "17px",
                  color: customisation?.textColor,
                }}
              >
                Your {parentProfile.profile_type.name}
              </Typography>
              <Divider
                variant="middle"
                sx={{
                  width: "80%",
                  border: `1px solid ${customisation?.textColor}`,
                  marginBottom: 1,
                }}
              />
              <ParentProfileButton parentProfile={parentProfile} />
            </Stack>
          )}
      </NavigationContextProvider>
    </Stack>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <StyledEngineProvider injectFirst>
      <ConfirmationDialogContext>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={classes.appBar}
            sx={{ backgroundColor: customisation?.mainColor }}
          >
            <CustomToolbar variant="dense" disableGutters>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />

              <ProfileSelector />
              <UserProfileDropdown />
              <AppSelector />

              <NotificationsContextProvider>
                <NotificationsDropdown />
              </NotificationsContextProvider>
            </CustomToolbar>
          </AppBar>

          <nav className={classes.drawer}>
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>

            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>

          <main className={classes.content}>{props.children}</main>
        </div>
      </ConfirmationDialogContext>
    </StyledEngineProvider>
  );
}
export default BaseComponent;
