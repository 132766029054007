import React, { useContext, useState } from "react";
import { Divider, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ConfirmationDialogActivator from "../../../components/confirmation-dialog/confirmation-dialog-activator";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import ProfileTypes from "../../../enums/profile-types";
import Compositions from "../../../enums/compositions";
import EditRoyaltyStatementModal from "./EditRoyaltyStatementModal";
import ReportSummaryModal from "./ReportSummaryModal";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

export default function ContextMenu({
  statement,
  reload,
  setImportedRoyaltyStatements,
}) {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [reportSummaryModalOpen, setReportSummaryModalOpen] = useState(false);
  const [completeStatement, setCompleteStatement] = useState();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setEditModalOpen(true);
  };

  const handleReImportClick = () => {
    history.push("import-royalty-statement");
  };

  const handleReportSummaryClick = () => {
    startGlobalLoader();
    API.get(ApiUri.ROYALTY_IMPORTER_FILE + "/" + statement.id).then(
      (response) => {
        const responseStatement = response.data;
        setCompleteStatement(responseStatement);
        setReportSummaryModalOpen(true);
        stopGlobalLoader();
      },
      (err) => {
        stopGlobalLoader();
      }
    );
  };

  const handleDelete = () => {
    startGlobalLoader();
    API.delete(ApiUri.ROYALTY_IMPORTER_FILE + "/" + statement?.id).then(
      (response) => {
        setImportedRoyaltyStatements(null);
        stopGlobalLoader();
        initSnackbarSuccess("Royalty Statement successfully deleted");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditClick}>Edit Payment</MenuItem>
        <MenuItem onClick={handleReportSummaryClick}>Report Summary</MenuItem>
        <MenuItem>
          <a
            href={statement?.file?.url}
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Download Original File
          </a>
        </MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem>
          <a
            href={statement?.error_file?.url}
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Download Errors
          </a>
        </MenuItem>
        <MenuItem onClick={handleReImportClick}>Re-Import Error Data</MenuItem>
      </Menu>
      <EditRoyaltyStatementModal
        modalOpened={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        setImportedRoyaltyStatements={setImportedRoyaltyStatements}
        statement={statement}
      />
      {reportSummaryModalOpen && (
        <ReportSummaryModal
          modalOpened={reportSummaryModalOpen}
          handleClose={() => setReportSummaryModalOpen(false)}
          statement={completeStatement}
        />
      )}
    </React.Fragment>
  );
}
