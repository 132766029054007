import {
  Dialog,
  AppBar,
  Typography,
  Grid,
  Slide,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Fab,
  Stack,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TransitionProps } from "@mui/material/transitions";
import React, { useContext, useEffect, useState } from "react";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { Territory } from "../../../../../models/composition/territory";
import { CompositionContext } from "../../../../../context/composition-context";
import { Close, Delete } from "@mui/icons-material";
import { SongWritersPercentageShareContext } from "../../../../../context/songwriters-percentage-share";
import useTerritories from "../../../../../hooks/territory/territories.hook";
import CustomToolbar from "../../../../../components/layout/titles/CustomToolbar";
import TerritoriesList from "./TerritoriesList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: "300px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made children required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ITerritoriesDialog {
  dialogOpened: boolean;
  onCloseDialog: Function;
}

export default function TerritoriesDialog({
  dialogOpened,
  onCloseDialog,
}: ITerritoriesDialog) {
  const classes = useStyles();
  const territories = useTerritories();

  const { composition, setComposition } = useContext(CompositionContext);
  const { calculateAnaraSongwritersShare } = useContext(
    SongWritersPercentageShareContext
  );
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const handleFieldChange = (event: any) => {
    const included = event.target.name === "included";
    const id = event.target.value;
    const territory: any = territories.find(
      (territory: Territory) => territory.id === id
    );
    processTerritory(territory, included);
  };

  /**
   * Add territory for composition
   *
   * @param territory
   * @returns
   */
  const processTerritory = (territory: Territory, included: boolean) => {
    startGlobalLoader();

    const data = {
      percentage: calculateAnaraSongwritersShare(),
      inclusion: included,
    };

    API.post(
      ApiUri.COMPOSITION +
        "/" +
        composition.id +
        ApiUri.TERRITORY +
        "/" +
        territory.id,
      data
    ).then(
      (response) => {
        stopGlobalLoader();
        setComposition(response.data);
        initSnackbarSuccess(
          `Territory added to the ${included ? "inclusion" : "exclusion"} list`
        );
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const removeTerritory = (territory: Territory) => {
    startGlobalLoader();
    API.delete(
      ApiUri.COMPOSITION +
        "/" +
        composition.id +
        ApiUri.TERRITORY +
        "/" +
        territory.id
    ).then(
      (response) => {
        stopGlobalLoader();
        setComposition(response.data);
        initSnackbarSuccess("Territory removed");
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  const closeDialog = () => {
    onCloseDialog();
  };

  return (
    <Dialog
      // fullScreen
      maxWidth="md"
      fullWidth
      open={dialogOpened}
      onClose={closeDialog}
      TransitionComponent={Transition}
      className={classes.dialog}
    >
      <AppBar className={classes.appBar}>
        <CustomToolbar>
          <Typography variant="h6" className={classes.title}>
            Select Territory
          </Typography>

          <IconButton
            edge="end"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
            size="large"
          >
            <Close />
          </IconButton>
        </CustomToolbar>
      </AppBar>
      <Grid container spacing={5} padding={3}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <InputLabel id="select-label">
              Licensed Territories to Include
            </InputLabel>
            <Select
              labelId="select-label"
              fullWidth
              name="included"
              onChange={handleFieldChange}
            >
              {territories?.map((territory: Territory, index: number) => {
                return (
                  <MenuItem key={`excluding${index}`} value={territory.id}>
                    {territory.name}
                  </MenuItem>
                );
              })}
            </Select>
            <TerritoriesList
              composition={composition}
              removeTerritory={removeTerritory}
              included
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <InputLabel id="select-label">
              Licensed Territories to Exclude
            </InputLabel>
            <Select
              labelId="select-label"
              fullWidth
              name="excluded"
              onChange={handleFieldChange}
            >
              {territories?.map((territory: Territory, index: number) => {
                return (
                  <MenuItem key={`excluding${index}`} value={territory.id}>
                    {territory.name}
                  </MenuItem>
                );
              })}
            </Select>
            <TerritoriesList
              composition={composition}
              removeTerritory={removeTerritory}
            />
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
}
