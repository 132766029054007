import { Box, Stack } from "@mui/material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import React, { useContext, useEffect, useState } from "react";
import { GlobalLoaderContext } from "../../../context/global-loader";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { SnackbarContext } from "../../../context/snackbar";
import ExportCwrModal from "./ExportCwrModal";
import ProfileTypes from "../../../enums/profile-types";
import RoleTypes from "../../../enums/role-types";
import { AuthContext } from "../../../context/auth";
import { useHistory } from "react-router-dom";
import GenerateLyricFindModal from "./GenerateLyricFindModal";
import GenerateIPRSModal from "./GenerateIPRSModal";
import GenerateSoundMouseModal from "./GenerateSoundMouseModal";

export default function FeedsGenerator() {
  const { user, userRole } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [exportCwrModalOpened, setExportCwrModalOpened] = useState(false);
  const [generateLyricFindModalOpened, setGenerateLyricFindModalOpened] =
    useState(false);
  const [generateIPRSModalOpened, setGenerateIPRSModalOpened] = useState(false);
  const [generateSoundMouseModalOpened, setGenerateSoundMouseModalOpened] =
    useState(false);
  const [showFeedsGeneratorPage, setShowFeedsGeneratorPage] = useState(false);
  const history = useHistory();

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowFeedsGeneratorPage(true);
    }
  }, []);

  const handleClick = (event) => {
    const name = event.currentTarget.name;
    if (name === "exportCatalogueAsCwr") {
      setExportCwrModalOpened(true);
    } else if (name === "LyricFind") {
      setGenerateLyricFindModalOpened(true);
    } else if (name === "IPRS") {
      setGenerateIPRSModalOpened(true);
    } else if (name === "SoundMouse") {
      setGenerateSoundMouseModalOpened(true);
    } else {
      startGlobalLoader();
      const value = event.currentTarget.value;

      API.post(ApiUri.FEED + "/" + value).then(
        (response) => {
          stopGlobalLoader();
          initSnackbarSuccess(`${name} Requested`);
        },
        (err) => {
          stopGlobalLoader();
          initSnackbarError(err);
        }
      );
    }
  };

  return (
    showFeedsGeneratorPage && (
      <React.Fragment>
        <Stack direction={"row"} marginBottom={4} spacing={4}>
          <ActionButtonSmall
            name="LyricFind"
            value="lyricfind"
            onClick={handleClick}
          >
            Generate LyricFind
          </ActionButtonSmall>
          <ActionButtonSmall name="IPRS" value="iprs" onClick={handleClick}>
            Generate IPRS
          </ActionButtonSmall>
          <ActionButtonSmall
            name="SoundMouse"
            value="soundmouse"
            onClick={handleClick}
          >
            Generate SoundMouse
          </ActionButtonSmall>
          <ActionButtonSmall
            name="exportCatalogueAsCwr"
            value="exportCatalogueAsCwr"
            onClick={handleClick}
          >
            Export catalogue as CWR
          </ActionButtonSmall>
        </Stack>
        <ExportCwrModal
          modalOpened={exportCwrModalOpened}
          handleClose={() => {
            setExportCwrModalOpened(false);
          }}
        />
        <GenerateLyricFindModal
          modalOpened={generateLyricFindModalOpened}
          handleClose={() => {
            setGenerateLyricFindModalOpened(false);
          }}
        />
        <GenerateIPRSModal
          modalOpened={generateIPRSModalOpened}
          handleClose={() => {
            setGenerateIPRSModalOpened(false);
          }}
        />
        <GenerateSoundMouseModal
          modalOpened={generateSoundMouseModalOpened}
          handleClose={() => {
            setGenerateSoundMouseModalOpened(false);
          }}
        />
      </React.Fragment>
    )
  );
}
