import {
  AppBar,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditRoyaltyStatementModal = ({
  modalOpened,
  handleClose,
  setImportedRoyaltyStatements,
  statement,
}) => {
  const classes = useStyles();
  const [editedStatement, setEditedStatement] = useState<any>(statement);
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closeEditRoyaltyStatementModal = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "tax_country":
        setEditedStatement({
          ...editedStatement,
          tax_country: value,
        });
        break;
      case "gbp":
        setEditedStatement({
          ...editedStatement,
          gbp: value,
        });
        break;
      case "tax_percentage":
        setEditedStatement({
          ...editedStatement,
          tax_percentage: value,
        });
        break;
      case "tax_amount":
        setEditedStatement({
          ...editedStatement,
          tax_amount: value,
        });
        break;

      //   case "name":
      //     setEditedStatement({ ...editedStatement, name: value });
      //     break;
      //   case "clientID":
      //     setEditedStatement({
      //       ...editedStatement,
      //       provider_params: {
      //         ...editedStatement.provider_params,
      //         client_id: value,
      //       },
      //     });
      //     break;
      //   case "secret":
      //     setEditedStatement({
      //       ...editedStatement,
      //       provider_params: {
      //         ...editedStatement.provider_params,
      //         secret: value,
      //       },
      //     });
      //     break;
      //   case "publishableKey":
      //     setEditedStatement({
      //       ...editedStatement,
      //       provider_params: {
      //         ...editedStatement.provider_params,
      //         publishable_key: value,
      //       },
      //     });
      //     break;
      //   case "secretKey":
      //     setEditedStatement({
      //       ...editedStatement,
      //       provider_params: {
      //         ...editedStatement.provider_params,
      //         secret_key: value,
      //       },
      //     });
      //     break;
    }
  };

  const handleDueDateChange = (event) => {
    const newDate = event;
    console.log("🚀 ~ handleDueDateChange ~ newDate:", newDate);
    setEditedStatement({
      ...editedStatement,
      date_to: newDate,
    });
  };

  const handleEnteredChange = (event) => {
    const newDate = event;
    setEditedStatement({
      ...editedStatement,
      entered_at: newDate,
    });
  };

  const handleSave = () => {
    const dateTo = new Date(editedStatement.date_to);
    const enteredAt = new Date(editedStatement.entered_at);
    const payload = {
      entered_at: enteredAt ? enteredAt.toISOString().slice(0, -1) : "",
      gbp: parseFloat(editedStatement.gbp),
      date_to: dateTo ? dateTo.toISOString().slice(0, -1) : "",
      tax_country: parseFloat(editedStatement.tax_country),
      tax_percentage: parseFloat(editedStatement.tax_percentage),
      tax_amount: parseFloat(editedStatement.tax_amount),
    };
    startGlobalLoader();
    API.put(
      ApiUri.ROYALTY_IMPORTER_FILE + "/" + editedStatement?.id,
      payload
    ).then(
      (response) => {
        initSnackbarSuccess("Royalty Statement edited");
        stopGlobalLoader();
        setImportedRoyaltyStatements(null);
        closeEditRoyaltyStatementModal();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        closeEditRoyaltyStatementModal();
      }
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={modalOpened}
        onClose={closeEditRoyaltyStatementModal}
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Royalty Statement
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeEditRoyaltyStatementModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack spacing={10} padding={5}>
          <Grid spacing={2} container>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleDueDateChange}
                  format="DD/MM/YYYY"
                  sx={{ width: "100%" }}
                  value={
                    editedStatement?.date_to
                      ? dayjs(editedStatement.date_to)
                      : null
                  }
                  label="Due Date"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={editedStatement?.gbp ?? ""}
                onChange={handleChange}
                name="gbp"
                label="GBP Amount Received"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleEnteredChange}
                  format="DD/MM/YYYY"
                  sx={{ width: "100%" }}
                  value={
                    editedStatement?.entered_at
                      ? dayjs(editedStatement.entered_at)
                      : null
                  }
                  label="Entered"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={editedStatement?.tax_country ?? ""}
                onChange={handleChange}
                name="tax_country"
                label="Tax Country"
                fullWidth
                InputProps={{
                  endAdornment: "%",
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={editedStatement?.tax_percentage ?? ""}
                onChange={handleChange}
                name="tax_percentage"
                label="Tax %"
                fullWidth
                InputProps={{
                  endAdornment: "%",
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={editedStatement?.tax_amount ?? ""}
                onChange={handleChange}
                name="tax_amount"
                label="Tax Amount"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <ActionButtonSmall onClick={handleSave}>Save</ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default EditRoyaltyStatementModal;
