import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth";
import RoleTypes from "../../enums/role-types";
import ProfileTypes from "../../enums/profile-types";
import { SnackbarContext } from "../../context/snackbar";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../../context/global-loader";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const UserAccessPermissions = () => {
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const history = useHistory();

  const [features, setFeatures] = useState(null);

  const seeThisPage =
    (userRole === RoleTypes.OWNER || userRole === RoleTypes.ADMIN) &&
    user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      startGlobalLoader();
      API.get(ApiUri.FUNCTIONALITY).then(
        (response) => {
          setFeatures(response.data.collection);
          stopGlobalLoader();
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
    }
  }, []);

  return features ? (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginBottom: 2 }}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>Page</b>
                </TableCell>
                <TableCell>
                  <b>Feature</b>
                </TableCell>
                <TableCell>
                  <b>Feature Type</b>
                </TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: "pointer" }}>
              {(features as any).map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell>
                      {row?.parent ? row?.parent?.label : row?.label}
                    </TableCell>
                    <TableCell>{row?.parent ? row?.label : "-"}</TableCell>
                    <TableCell>{capitalizeFirstLetter(row?.type)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default UserAccessPermissions;
