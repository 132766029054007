import React, { useContext } from "react";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  Menu,
  Stack,
  Tooltip,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AuthContext, IAuthContext } from "../context/auth";
import {
  GlobalLoaderContext,
  IGlobalLoaderContext,
} from "../context/global-loader";
import AppIcon from "../components/app-icon";

export default function AppSelector() {
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader } =
    useContext<IGlobalLoaderContext>(GlobalLoaderContext);
  const [serviceAppMenuAnchorEl, setServiceAppMenuAnchorEl] =
    React.useState(null);

  const showMMDZ = (user as any)?.applications
    ? (user as any)?.applications.some((app) => app === "mmdz")
    : false;

  const showMMPZ = (user as any)?.applications
    ? (user as any)?.applications.some((app) => app === "mmpz")
    : false;

  const testSite =
    window.location.hostname.includes("test") ||
    window.location.hostname.includes("localhost");

  const handleServiceAppMenuClick = (event: any) => {
    setServiceAppMenuAnchorEl(event.currentTarget);
  };

  const handleServiceAppMenuClose = () => {
    setServiceAppMenuAnchorEl(null);
  };

  const handleAppSelect = () => {
    handleServiceAppMenuClose();
    startGlobalLoader();
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        color="inherit"
        onClick={handleServiceAppMenuClick}
        size="large"
      >
        <AppsIcon />
      </IconButton>

      <Menu
        anchorEl={serviceAppMenuAnchorEl}
        keepMounted
        open={Boolean(serviceAppMenuAnchorEl)}
        onClose={handleServiceAppMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid container maxWidth={"300px"}>
          {
            <>
              <Grid item xs={6} justifyContent={"center"} alignItems={"center"}>
                <Stack width={"100%"} justifyContent={"center"}>
                  <AppIcon
                    imageSource="/MMAZ-logo-round.png"
                    appName={"My Music Account Zone"}
                    linkUrl={`https://mmaz${
                      testSite ? ".test" : ""
                    }.myclientzone.com`}
                    small
                  />
                </Stack>
              </Grid>
              {showMMDZ && (
                <Grid
                  item
                  xs={6}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack width={"100%"} justifyContent={"center"}>
                    <AppIcon
                      imageSource="/MMDZ-logo-round.png"
                      appName={"My Music Distribution Zone"}
                      linkUrl={`https://mmdz${
                        testSite ? ".test" : ""
                      }.myclientzone.com`}
                      small
                    />
                  </Stack>
                </Grid>
              )}
              {showMMPZ && (
                <Grid
                  item
                  xs={6}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Stack width={"100%"} justifyContent={"center"}>
                    <AppIcon
                      imageSource="/MMPZ-logo-round.png"
                      appName={"My Music Publishing Zone"}
                      linkUrl="/dashboard"
                      small
                    />
                  </Stack>
                </Grid>
              )}
            </>
          }
        </Grid>
      </Menu>
    </Box>
  );
}
