import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "./context/auth";
import NotFound from "./components/not-found";
import BaseComponent from "./layout/base-component";
import PageTransition from "./layout/page-transition";
import EditProfile from "./pages/edit-profile";
import ManageSubProfiles from "./pages/sub-profiles/manage-sub-profiles";
import NewSubProfile from "./pages/sub-profiles/new-sub-profile";
import EditSubProfile from "./pages/sub-profiles/manage-sub-profiles/edit-sub-profile/index";
import AccountsAppRegistration from "./components/accounts-app-registration";
import ManageSongs from "./pages/songs/manage-songs";
import EditSong from "./pages/songs/manage-songs/edit-song/index";
import AddNewSong from "./pages/songs/add-new-song/index";
import ProcessSong from "./pages/songs/manage-songs/process-song/index";
import FeedsGenerator from "./pages/administration/feeds-generator";
import Platforms from "./pages/settings/platforms";
import ManagePackages from "./pages/products/packages/index";
import ManageSubscriptions from "./pages/products/subscriptions/index";
import ViewHelp from "./pages/help/view";
import EditHelp from "./pages/help/edit";
import SuccessPage from "./pages/songs/success-page";
import ErrorPage from "./pages/songs/error-page";
import NewProfilePage from "./components/NewProfilePage";
import Contracts from "./pages/settings/contracts";
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import ManageSubscriptionsSongwriter from "./pages/payments/manage-subscriptions";
import ChooseInvitationPageForExistingProfiles from "./components/ChooseInvitationPageForExistingProfiles";
import DefaultRoayaltyValues from "./pages/settings/default-royalty-values";
import ImportRoyaltyStatement from "./pages/royalties/import-royalty-statement";
import UserAccessPermissions from "./pages/user-access-permissions";
// import PreSignupPage from "./components/PreSignupPage";
// import { Prompt, useHistory } from "react-router-dom";

const params = new URLSearchParams(window.location.search);
const parentParam = params.get("parent") ?? "";
const profileTypeParam = params.get("profile_type") ?? "";
const newProfileSearchParams = `?parent=${parentParam}&profile_type=${profileTypeParam}`;

let showInvitationsPage = false;
const currentUrl = window.location.href;

// Check if the URL contains "invitation/accept"
if (currentUrl.includes("invitation/accept")) {
  showInvitationsPage = true;
}

const App = () => {
  const { user } = useContext(AuthContext);

  // const [isBlocking, setIsBlocking] = useState(false);
  // const editSongPath = "/songs/edit/";
  // const history = useHistory();

  // useEffect(() => {
  //   const unlisten = history.listen((location, action) => {
  //     console.log("🚀 ~ unlisten ~ location:", location);
  //     if (!location.pathname.startsWith(editSongPath)) {
  //       setIsBlocking(true);
  //     } else {
  //       setIsBlocking(false);
  //     }
  //   });

  //   return () => {
  //     unlisten();
  //   };
  // }, [history]);

  return user ? (
    showInvitationsPage ? (
      <BrowserRouter>
        <Switch>
          <Route
            path="/invitation/accept"
            component={PageTransition(ChooseInvitationPageForExistingProfiles)}
            search={newProfileSearchParams}
          />
        </Switch>
      </BrowserRouter>
    ) : user.last_accessed_profile ? (
      <BrowserRouter
        basename={
          user.last_accessed_profile
            ? user.last_accessed_profile.url_name
            : user.default_profile.url_name
        }
      >
        <BaseComponent>
          {/* <Prompt
            when={isBlocking}
            message={(location) =>
              `Are you sure you want to leave this page? Your progress so far will be lost`
            }
          /> */}
          <Switch>
            <Redirect exact={true} from="/" to="/dashboard" />
            <PrivateRoute
              path="/dashboard"
              component={PageTransition(Dashboard)}
            />

            {/* Songs */}
            <PrivateRoute
              path="/songs/manage"
              component={PageTransition(ManageSongs)}
            />
            <PrivateRoute
              path="/songs/new"
              component={PageTransition(AddNewSong)}
            />
            <PrivateRoute
              path="/songs/success"
              component={PageTransition(SuccessPage)}
            />
            <PrivateRoute
              path="/songs/error"
              component={PageTransition(ErrorPage)}
            />
            <PrivateRoute
              path="/songs/edit/:id"
              component={PageTransition(EditSong)}
            />
            <PrivateRoute
              path="/songs/process/:id"
              component={PageTransition(ProcessSong)}
            />
            {/* /Songs */}

            {/* Profile */}
            <PrivateRoute
              path="/edit-profile"
              component={PageTransition(EditProfile)}
            />
            {/* /Profile */}

            {/* Sub-Profiles */}
            <PrivateRoute
              exact={true}
              path="/sub-profiles/manage"
              component={PageTransition(ManageSubProfiles)}
            />
            <PrivateRoute
              exact={true}
              path="/sub-profiles/new"
              component={PageTransition(NewSubProfile)}
            />
            <PrivateRoute
              exact={true}
              path="/sub-profiles/edit/:id"
              component={PageTransition(EditSubProfile)}
            />
            {/* /Sub-Profiles */}

            {/* Products */}
            <PrivateRoute
              exact={true}
              path="/products/packages"
              component={PageTransition(ManagePackages)}
            />
            <PrivateRoute
              exact={true}
              path="/products/subscriptions"
              component={PageTransition(ManageSubscriptions)}
            />
            {/* /Products */}

            {/* Exporters */}
            <PrivateRoute
              exact={true}
              path="/exporters/feeds-generator"
              component={PageTransition(FeedsGenerator)}
            />
            {/* Exporters */}

            {/* Royalties */}
            <PrivateRoute
              exact={true}
              path="/royalties/import-royalty-statement"
              component={PageTransition(ImportRoyaltyStatement)}
            />
            <PrivateRoute
              exact={true}
              path="/royalties/default-royalty-values"
              component={PageTransition(DefaultRoayaltyValues)}
            />
            {/* Royalties */}

            {/* Payments */}
            <PrivateRoute
              exact={true}
              path="/payments/manage-subscriptions"
              component={PageTransition(ManageSubscriptionsSongwriter)}
            />
            {/* Payments */}

            {/* Help */}
            <PrivateRoute
              exact={true}
              path="/help/view"
              component={PageTransition(ViewHelp)}
            />
            <PrivateRoute
              exact={true}
              path="/help/edit"
              component={PageTransition(EditHelp)}
            />
            {/* Help */}

            {/* Settings */}
            <PrivateRoute
              path="/settings/platforms"
              component={PageTransition(Platforms)}
            />
            <PrivateRoute
              path="/settings/contracts"
              component={PageTransition(Contracts)}
            />
            {/* /Settings */}

            {/* User Access Permissions */}
            <Route
              path="/user-access-permissions"
              component={PageTransition(UserAccessPermissions)}
            />
            {/* User Access Permissions */}

            {/* Public */}
            <Route
              path="/:url_name/public/terms-of-use"
              component={PageTransition(TermsAndConditionsPage)}
            />
            {/* Public */}

            <Route path="*" component={PageTransition(NotFound)} />
          </Switch>
        </BaseComponent>
      </BrowserRouter>
    ) : (
      <BrowserRouter>
        <Switch>
          <Redirect
            exact
            from="/dashboard"
            to="/new-profile/registration-form"
            // to="/pre-signup"
          />
          <Route
            path="/new-profile/registration-form"
            component={PageTransition(NewProfilePage)}
            search={newProfileSearchParams}
          />
          {/* <Route path="/pre-signup" component={PageTransition(PreSignupPage)} /> */}
          <Route
            path="/new-profile/:url_name/:profile_type/public/terms-of-use"
            component={PageTransition(TermsAndConditionsPage)}
          />
        </Switch>
      </BrowserRouter>
    )
  ) : (
    <BrowserRouter>
      <Switch>
        <Redirect
          exact
          from="/new-profile/registration-form"
          to="/accounts-app-registration"
        />
        <Route
          path="/accounts-app-registration"
          component={PageTransition(AccountsAppRegistration)}
        />
        <Route
          path="/:url_name/:profile_type/public/terms-of-use"
          component={PageTransition(TermsAndConditionsPage)}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
