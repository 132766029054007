import React, { useContext } from "react";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Notifications } from "@mui/icons-material";
import { NotificationsContext } from "../context/notifications";
import DateTime from "../components/utilities/date-time";
import Colors from "../enums/colors";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { Notification } from "../models/notification/notification";
import { CustomisationContext } from "../context/customisation";

const useStyles = makeStyles(() => {
  return {
    profileDropdownBox: {
      marginRight: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
});

export default function NotificationsDropdown() {
  const classes = useStyles();
  const { customisation } = useContext(CustomisationContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    notifications,
    setNotifications,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  } = useContext(NotificationsContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    if (unreadNotificationsCount === 0) {
      return;
    }

    API.post(ApiUri.USER_ME + ApiUri.NOTIFICATIONS + ApiUri.READ_ALL).then(
      (response) => {
        setNotifications(response.data.collection);
        setUnreadNotificationsCount(0);
      }
    );
  };

  const first50Notifications = notifications.slice(0, 50);

  const handleOnNotificationClick = (notification: Notification) => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.profileDropdownBox}
        onClick={handleClick}
      >
        <IconButton size="large">
          <Badge
            badgeContent={unreadNotificationsCount}
            overlap="rectangular"
            color="error"
          >
            <Notifications sx={{ color: customisation?.textColor }} />
          </Badge>
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          style: {
            height: "80vh",
          },
        }}
      >
        <List style={{ maxWidth: "400px" }}>
          {notifications.length > 0 ? (
            first50Notifications.map((notification) => {
              return (
                <ListItem
                  key={notification.id}
                  alignItems="flex-start"
                  button
                  divider
                  style={{ opacity: notification.is_read ? "0.8" : "1" }}
                  onClick={() => handleOnNotificationClick(notification)}
                >
                  <Badge
                    color="error"
                    style={{ maxWidth: "400px" }}
                    variant={notification.is_read ? "standard" : "dot"}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt="N"
                        src={"/mmpz-logo-small.png"}
                        style={{ backgroundColor: "lightgray" }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ padding: 20 }}
                      primary={notification.notification_title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            color="primary"
                            variant="subtitle1"
                            component="span"
                          >
                            <b>{notification.notification_text}</b>
                          </Typography>
                          <br></br>
                          <Typography variant="caption" component="span">
                            <DateTime>{notification.created_at}</DateTime>
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </Badge>
                </ListItem>
              );
            })
          ) : (
            <ListItem>No notifications!</ListItem>
          )}
        </List>
      </Menu>
    </React.Fragment>
  );
}
