import { Card, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ProfileTypes from "../../../enums/profile-types";

const ProductsPage = ({ products }) => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [paymentGateways, setPaymentGateways] = useState<any>(null);
  const { user } = useContext<IAuthContext>(AuthContext);

  useEffect(() => {
    API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
      (response) => {
        const profileObject = response.data;

        API.get(
          ApiUri.PROFILE +
            "/" +
            profileObject.parent.id +
            ApiUri.PAYMENT_GATEWAY
        ).then(
          (response) => {
            const listOfPaymentGateways = response.data.collection;
            const listOfActivePaymentGateways = listOfPaymentGateways.filter(
              (paymentGateway) => paymentGateway.status
            );
            setPaymentGateways(listOfActivePaymentGateways);
            stopGlobalLoader();
          },
          (error) => {
            initSnackbarError(error);
            stopGlobalLoader();
          }
        );
      }
    );
  }, []);

  return (
    <React.Fragment>
      {paymentGateways && (
        <React.Fragment>
          <h3>
            It seems that you don't have any active packages or subscriptions.
          </h3>
          <h3>Please select an option:</h3>
          <Grid container spacing={2}>
            {products
              .filter((product) => product.enabled)
              .filter((product) => product.visible)
              .filter((product: any) => !product.visible_to_admin_only)
              .filter((product: any) => product.type === "Subscription")
              .map((product) => (
                <Grid item key={product.id} xs={6} md={3}>
                  <ProductCard
                    product={product}
                    paymentGateways={paymentGateways}
                  />
                </Grid>
              ))}
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ProductsPage;
