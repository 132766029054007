import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ListItemButton, Typography } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Collapse, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

import Colors from "../enums/colors";
import { NavigationContext } from "../context/navigation";
import { Stack } from "@mui/system";
import { CustomisationContext } from "../context/customisation";
import { ConfirmationDialogContext } from "../context/confirmation-dialog-context";

export default function NavigationItem({ page }: any) {
  const { customisation } = useContext(CustomisationContext);
  const useStyles = makeStyles(() => ({
    navLink: {
      textDecoration: "none",
    },
    listItem: {
      minHeight: 30,
      "&:hover": {
        backgroundColor: customisation?.backgroundColor,
      },
      paddingTop: 0,
      paddingBottom: 0,
    },
    nestedListItem: {
      paddingLeft: 50,
    },
    icon: {
      alignContent: "center",
      paddingRight: 8,
      transform: "scale(0.75)",
      color: customisation?.textColor,
    },
    text: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "17px",
      color: customisation?.textColor,
    },
  }));
  const classes = useStyles();
  const { navigation, setNavigation } = useContext(NavigationContext);
  const location = useLocation();
  const { showConfirmation, setDialogTitle, setDialogContent } = useContext(
    ConfirmationDialogContext
  );
  const history = useHistory();

  const handleClick = async (pagePath = null) => {
    if (pagePath) {
      setDialogContent(
        "Are you sure you want to leave this page? Your progress so far will be lost"
      );
      const result = await showConfirmation();
      if (result) {
        history.push(pagePath);
        updateNavigation(page);
      }
    } else {
      updateNavigation(page);
    }
  };

  const handleChildClick = async (child: any, childPath = null) => {
    if (childPath) {
      setDialogContent(
        "Are you sure you want to leave this page? Your progress so far will be lost"
      );
      const result = await showConfirmation();
      if (result) {
        history.push(childPath);
        updateNavigation(child);
      }
    } else {
      updateNavigation(child);
    }
  };

  const updateNavigation = (activeItem) => {
    const updatedNavigation = navigation.map((navigationPage: any) => {
      navigationPage.open =
        navigationPage.name === page.name ? !navigationPage.open : false;
      navigationPage.children = navigationPage.children.map(
        (navigationPageChild: any) => {
          navigationPageChild.active =
            navigationPageChild.name === activeItem.name;
          return navigationPageChild;
        }
      );
      return navigationPage;
    });
    setNavigation(updatedNavigation);
  };

  if (page.children.length === 0) {
    return location.pathname.startsWith("/songs/new") ||
      location.pathname.startsWith("/songs/edit") ? (
      <ListItemButton
        onClick={() => handleClick(page.path)}
        className={classes.listItem}
        sx={{
          backgroundColor: page.open ? customisation?.backgroundColor : "",
        }}
      >
        <Stack className={classes.icon}>{page.icon}</Stack>
        <Typography className={classes.text}>{page.name}</Typography>
      </ListItemButton>
    ) : (
      <NavLink to={page.path} className={classes.navLink}>
        <ListItemButton
          onClick={() => handleClick()}
          className={classes.listItem}
          sx={{
            backgroundColor: page.open ? customisation?.backgroundColor : "",
          }}
        >
          <Stack className={classes.icon}>{page.icon}</Stack>
          <Typography className={classes.text}>{page.name}</Typography>
        </ListItemButton>
      </NavLink>
    );
  }

  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => handleClick()}
        className={classes.listItem}
      >
        <Stack className={classes.icon}>{page.icon}</Stack>
        <ListItemText>
          <Typography className={classes.text}>{page.name}</Typography>
        </ListItemText>
        <Typography className={classes.text}>
          {page.open ? "-" : "+"}
        </Typography>
      </ListItemButton>

      {page.children.map((child: any, index: number) =>
        location.pathname.startsWith("/songs/new") ||
        location.pathname.startsWith("/songs/edit") ? (
          <Collapse in={page.open} unmountOnExit key={index}>
            <List disablePadding>
              <ListItemButton
                onClick={() => handleChildClick(child, child.path)}
                className={classes.listItem + " " + classes.nestedListItem}
                sx={{
                  backgroundColor: child.active
                    ? customisation?.backgroundColor
                    : "",
                }}
              >
                <ListItemText>
                  <Typography className={classes.text}>{child.name}</Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
        ) : (
          <NavLink key={index} to={child.path} className={classes.navLink}>
            <Collapse in={page.open} unmountOnExit>
              <List disablePadding>
                <ListItemButton
                  onClick={() => handleChildClick(child)}
                  className={classes.listItem + " " + classes.nestedListItem}
                  sx={{
                    backgroundColor: child.active
                      ? customisation?.backgroundColor
                      : "",
                  }}
                >
                  <ListItemText>
                    <Typography className={classes.text}>
                      {child.name}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          </NavLink>
        )
      )}
    </React.Fragment>
  );
}
