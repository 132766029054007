import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import { Composition } from "../../../../models/composition/composition";
import Compositions from "../../../../enums/compositions";
import { AuthContext } from "../../../../context/auth";
import ProfileTypes from "../../../../enums/profile-types";
import RoleTypes from "../../../../enums/role-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

interface ICompositionCard {
  composition: Composition;
  disableEdit: boolean;
}

export default function CompositionCard({
  composition,
  disableEdit,
}: ICompositionCard) {
  const classes = useStyles();
  const history = useHistory();
  const [showLyrics, setShowLyrics] = useState(false);

  const { user, userRole } = useContext(AuthContext);

  const handleEdit = () => {
    history.push("/songs/edit/" + composition.songs[0].id);
  };

  const toggleLyrics = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowLyrics(!showLyrics);
  };

  const languagesNames = composition.composition_languages.map(
    (obj) => obj.language.name
  );
  const languagesNamesString =
    languagesNames.length > 0 ? languagesNames.join(", ") : " ";

  return composition ? (
    <Grid item xs={12} className={classes.gridItem}>
      <Paper elevation={3}>
        <Box p={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Title"
                value={composition.title || " "}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Tunecode"
                value={composition.tunecode || " "}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="ISWC"
                value={composition.iswc || " "}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {(user?.last_accessed_profile?.profile_type?.name ===
              ProfileTypes.SERVICE ||
              user?.last_accessed_profile?.profile_type?.name ===
                ProfileTypes.PUBLISHER_ADMINISTRATOR) &&
              (composition.status === Compositions.STATUS_PUBLISHED ||
                composition.status === Compositions.STATUS_TAKEN_DOWN) &&
              (userRole === RoleTypes.ADMIN || user?.is_mmpz_service_admin) && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled
                    className={classes.textField}
                    label="Musixmatch ID"
                    value={composition?.work_number_id}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              )}

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Status"
                value={Compositions.getStatusDisplayName(composition.status)}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Languages"
                value={languagesNamesString}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Title Language"
                value={composition?.title_language?.name || " "}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label="Show Lyrics"
              control={<Switch checked={showLyrics} onChange={toggleLyrics} />}
            ></FormControlLabel>
          </Grid>

          {showLyrics ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Lyrics"
                name="lyrics"
                variant="outlined"
                multiline
                rows={10}
                value={composition.lyrics ?? ""}
              />
            </Grid>
          ) : null}

          {composition.status === Compositions.STATUS_DRAFT ? (
            <Grid container style={{ marginTop: 25 }}>
              <Grid item>
                <ActionButtonSmall onClick={handleEdit} disabled={disableEdit}>
                  Edit Song
                </ActionButtonSmall>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
